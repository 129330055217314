body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  --antd-wave-shadow-color: #69008C;
}

body:after {
  content: "";
  display: block;
  position: absolute;
  background: url("../assets/image/login/right.jpg") no-repeat -10000px -1000px
}

html {
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  color: #fff;
  background: #69008C !important;
}

.no-padding {
  padding: 0px !important;
}

.padding-content {
  padding: 4px 0;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: #69008C;
}

div:focus {
  outline: none;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.inlineBlock {
  display: block;
}

a:hover {
  color: #69008C !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 2px solid #69008C !important;
}

.ant-input-affix-wrapper-focused {
  border: 2px solid #69008C !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border: 2px solid #E1000F !important;
  box-shadow: none;
}

.ant-form-item-explain-error {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #E1000F !important;
}

.ant-input:placeholder-shown {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #9D9D9D !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background: #fff;
  border: 2px solid #E1000F !important;
  box-shadow: none !important;
}

.ant-input-group-addon {
  border: 1px solid #8C8C8C;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: none !important;
}

.ant-input-group-addon:hover {
  border: 2px solid #69008C !important;
  box-shadow: none !important;
}

.ant-input-group-addon:focus {
  border: 2px solid #69008C !important;
  box-shadow: none !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type=checkbox] {
  border-color: #EF3131 !important;
}

.ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
  border-radius: 5px !important;
  top: 2px !important;
}

.ant-checkbox-input.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border: 2px solid #EF3131 !important;
  border-radius: 5px;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #8C8C8C !important;
  border-radius: 5px;
  background-color: #FFFFFF !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #EF3131 !important;
  border: 2px solid #EF3131 !important;
  border-radius: 5px;
}

.ant-form input[type=checkbox]:focus {
  outline-color: #EF3131 !important;
  border-radius: 5px;
}

.ant-checkbox-checked:after {
  width: 18px !important;
  height: 18px !important;
  border: 2px solid #EF3131 !important;
  border-radius: 5px !important;
  top: 2px !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  color: #868E96;
  margin-left: -5px;
  line-height: 22px;
  white-space: nowrap;
}

.ant-breadcrumb a:hover,
.ant-breadcrumb li:last-child,
.ant-breadcrumb li:last-child a {
  color: #E1000F !important;
}

.ant-breadcrumb {
  font-family: 'Segoe UI', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #9D9D9D !important;
}

.ant-table {
  background: #F9F9F9 !important;
}

.ant-table-thead>tr>th {
  background: #E1F0E7 !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center !important;
  padding: 10px 16px !important;
  color: #666666 !important;
  border-bottom: 6px solid #F9F9F9 !important;
}

.ant-table-tbody>tr>td {
  background: #F6FEF9 !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 16px !important;
  text-align: center;
  color: #000000 !important;
  border-bottom: 6px solid #F9F9F9 !important;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.ant-table-measure-row {
  visibility: collapse;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}

.ant-table-pagination.ant-pagination {
  margin-top: 31px;
  display: flex !important;
  align-items: center !important;
}

.ant-pagination-item {
  min-width: 22px !important;
  height: 22px !important;
  border: 0px !important;
  background: transparent !important;
}

.ant-pagination-item:hover {
  border-color: #69008C !important;
}

.ant-pagination-item a {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #69008C;
}

.ant-pagination-item-active {
  border-radius: 5px !important;
  border: 1px solid #69008C !important;
}

.ant-pagination-item-active a {
  color: #69008C !important;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border: 0px !important;
  background: transparent !important;
  height: 22px !important;
}

.anticon svg {
  display: block !important;
  margin: auto;
}

.ant-modal-header {
  background: #69008C !important;
  border-radius: 4px 4px 0px 0px !important;
}

.ant-modal-title {
  font-family: 'Segoe UI', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #FFFFFF !important;
}

.ant-form-item-label>label {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 22px;
  color: #000000 !important;
}

.ant-modal-body {
  padding: 41px 69px 52px 69px !important;
}



.ant-btn-cancel {
  width: 120px !important;
  height: 40px !important;
  background: #FFFFFF !important;
  border: 1px solid #69008C !important;
  border-radius: 18px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;
  color: #69008C !important;
}

.ant-btn-cancel:hover,
.ant-btn-cancel:focus {
  border: 1px solid rgba(105, 0, 140, 0.3) !important;
  color: rgba(105, 0, 140, 0.3) !important;
}

.ant-btn-cancel:active {
  border: 1px solid #69008C !important;
  color: #69008C !important;
}

.ant-btn-save {
  width: 120px !important;
  height: 40px !important;
  background: #69008C !important;
  border: 1px solid #69008C !important;
  border-radius: 18px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF !important;
}

.ant-btn-save:hover,
.ant-btn-save:focus {
  background: rgba(105, 0, 140, 0.4) !important;
  border: 1px solid rgba(105, 0, 140, 0.4) !important;
}

.ant-btn-save:active {
  background-color: #69008C !important;
  border: 1px solid #69008C !important;
}

.ant-btn-saveEnable {
  width: auto !important;
  height: 40px !important;
  background: #69008C !important;
  border: 1px solid #69008C !important;
  border-radius: 18px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF !important;
}

.ant-btn-saveEnable:hover,
.ant-btn-saveEnable:focus {
  background: rgba(105, 0, 140, 0.4) !important;
  border: 1px solid rgba(105, 0, 140, 0.4) !important;
}

.ant-btn-saveEnable:active {
  background-color: #69008C !important;
  border: 1px solid #69008C !important;
}


.ant-btn-search {
  width: 140px !important;
  height: 45px !important;
  background-color: #69008C !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  font-family: 'Segoe UI', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 16px !important;
  text-align: center !important;
  border: 1px solid #69008C !important;
  margin-right: 20px !important;
}

.ant-btn-search:hover,
.ant-btn-search:focus {
  background: rgba(105, 0, 140, 0.4) !important;
  border: 1px solid rgba(105, 0, 140, 0.4) !important;
}

.ant-btn-search:active {
  background-color: #69008C !important;
  border: 1px solid #69008C !important;
}

.ant-btn-reset {
  width: 140px !important;
  height: 45px !important;
  background-color: #FFFFFF !important;
  color: #69008C !important;
  border-radius: 5px !important;
  font-family: 'Segoe UI', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 16px !important;
  text-align: center !important;
  border: 1px solid #69008C !important;
}

.ant-btn-reset:hover,
.ant-btn-reset:focus {
  border: 1px solid rgba(105, 0, 140, 0.3) !important;
  color: rgba(105, 0, 140, 0.3) !important;
}

.ant-btn-reset:active {
  border: 1px solid #69008C !important;
  color: #69008C !important;
}


.ant-btn-next {
  width: 120px !important;
  height: 40px !important;
  background: #69008C !important;
  border: 1px solid #69008C !important;
  border-radius: 18px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;
  opacity: 0.4;
  color: #FFFFFF !important;
}

.ant-btn-backHome {
  width: 105px !important;
  height: 32px !important;
  background: #E1000F !important;
  box-shadow: 0px 4px 10px rgba(225, 0, 15, 0.1) !important;
  border-radius: 16px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  padding: 0px !important;
  color: #FFFFFF !important;
  border: 1px solid #E1000F !important;
}

.ant-btn-backHome:hover,
.ant-btn-backHome:focus {
  border: 0px !important;
  background: rgba(225, 0, 15, 0.3) !important;
}

.ant-modal-footer {
  padding: 15px 28px !important;
}

.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 9px 20px !important;
}

.ant-list {
  border-radius: 10px 10px 10px 10px !important;
}

.ant-list-split .ant-list-header {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  background: #E1F0E7;
}

.ant-list-item {
  background: #F6FEF9;
}

.ant-steps-item-title {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #8C8C8C;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #56DE8F !important;
  border-color: #56DE8F !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  width: 34px !important;
  height: 34px !important;
  color: #FFFFFF !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
  background-color: #56DE8F !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
  background-color: #DEDDDD !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail:after {
  background-color: #DEDDDD !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #56DE8F !important;
  border-color: #56DE8F !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #DEDDDD !important;
  border-color: #DEDDDD !important;
}

.ant-message .ant-message-notice-content {
  background: #F3FFF8 !important;
  box-shadow: 0px 4px 30px rgba(105, 0, 140, 0.1) !important;
  border-radius: 10px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  color: #000000 !important;
}

.ant-divider-vertical {
  border-left: 1px solid #666666 !important;
  margin: 0 10px 0px 0px !important;
}

.ant-modal-close-x {
  color: white !important;
}

.ant-pagination-next button:hover,
.ant-pagination-prev button:hover {
  color: #69008C !important;
}


.ant-tooltip {
  max-width: 292px !important;
  min-width: 40px !important;
}

.ant-tooltip-inner {
  background-color: #FFFFFF !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  border-radius: 4px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #333333 !important;
  margin-top: 4px !important;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 0px !important
}

.ant-tooltip-placement-left {
  padding-right: 10px !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #FFFFFF !important;
  margin-top: 2px !important;
  z-index: 10000000 !important;
  margin-left: 2px !important;
}

.device-link {
  color: #000000;
}

.device-link:hover {
  color: #69008C !important;
  text-decoration: underline;
}

.copy-button-con {
  margin-left: 4px;
}

.copy-button-bottom {
  margin-bottom: -2px;
}

.copy-button {
  width: 9px;
  height: 9px;
}

.list-select {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.continuedps-top-words {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin: 0 0 0 0;
  margin-right: 40px;
}

.device-id-copy {
  display: flex;
  flex-wrap: nowrap;
}

.edit-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #69008C;
  border-radius: 5px;
  margin-bottom: 0px;
  background: #FFFFFF;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #69008C;
}

.delete-button {
  width: 30px;
  height: 30px;
  background-color: #69008C;
  background-image: url('../assets/image/account/DE.png');
  background-size: 15px 15px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-bottom: 0px;
}

.editss-button {
  min-width: 33px;
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #69008C;
  border-radius: 5px;
  margin-bottom: 0px;
  background: #FFFFFF;
  padding: 0px 3px;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #69008C;
}

.edits-buttons {
  width: auto;
  min-width: 60px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #69008C;
  border-radius: 5px;
  margin-bottom: 0px;
  background-color: #FFFFFF;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #69008C;
  transform: scale(0.83);
  -webkit-transform: scale(0.83);
  transform-origin: 0 center;
  -webkit-transform-origin: 0 center;
}

.delete-button:hover,
.delete-button:focus {
  background-color: rgba(105, 0, 140, 0.4) !important;
  border: 1px solid rgba(105, 0, 140, 0.4) !important;
}

.delete-button:active {
  background-color: #69008C !important;
  border: 1px solid #69008C !important;
}

.edit-button:hover,
.edit-button:focus,
.editss-button:hover,
.editss-button:focus,
.edits-buttons:hover,
.edits-buttons:focus {
  border: 1px solid rgba(105, 0, 140, 0.3) !important;
  color: rgba(105, 0, 140, 0.3) !important;
}

.edit-button:active,
.editss-button:active,
.edits-buttons:active {
  border: 1px solid #69008C !important;
  color: #69008C !important;
}

.main-toPage-input {
  width: 33px !important;
  height: 22px !important;
  text-align: center;
  min-width: 22px;
  padding: 0 2px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
}

.main-page-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;

}

.page-to-margin {
  margin-right: 4px;
}

.page-to-margin-left {
  margin-left: 4px;
}

.main-toPage-input .ant-input-number-input {
  padding: 0 2px !important;
  height: 20px !important;
  text-align: center !important;
  font-family: 'Segoe UI', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-input-number:focus,
.ant-input-number:hover {
  border: 1px solid #69008C !important;
  box-shadow: none !important;
}

.ant-input-number-focused {
  border: 1px solid #69008C !important;
  box-shadow: none !important;
}

.page-Previous {
  color: #69008C;
}

.total-pages {
  margin-left: 10px;
  margin-right: 20px;
}

.page-button {
  width: 53px !important;
  height: 24px !important;
  background: rgba(105, 0, 140, 0.8) !important;
  border-radius: 3px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF !important;
  margin-left: 10px;
}


.page-button:hover,
.page-button:focus {
  background-color: rgba(105, 0, 140, 0.4) !important;
  border: 1px solid rgba(105, 0, 140, 0.4) !important;
  color: #FFFFFF !important;
}

.page-button:active {
  background-color: #69008C !important;
  border: 1px solid #69008C !important;
  color: #FFFFFF !important;
}

.page-button-dis {
  width: 53px !important;
  height: 24px !important;
  border-radius: 3px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF !important;
  margin-left: 10px;
  background: #D9D9D9 !important;
}

.page-button-dis:hover {
  color: #FFFFFF !important;
}

.page-Previous-types {
  padding: 3px 7px 3px 15px !important;
  background: #FFFFFF !important;
  border: 0.5px solid #999999 !important;
  border-radius: 3px !important;
  font-family: 'Segoe UI', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #999999 !important;
  background-image: url('../assets/image/monitor/prv.png') !important;
  background-size: 4px 8px !important;
  background-repeat: no-repeat !important;
  background-position: 7px center !important;

}

.page-Previous-types-next {
  padding: 3px 15px 3px 7px;
  background: #FFFFFF;
  border: 0.5px solid #999999;
  border-radius: 3px;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #999999;
  background-image: url('../assets/image/monitor/prvnext.png');
  background-size: 4px 8px;
  background-repeat: no-repeat;
  background-position: 48px 8px;
}

.page-Previous-types-next:hover {
  background-image: url('../assets/image/monitor/pageHovernext.png');
  border: 0.5px solid #69008C;
  color: #69008C;
}

.page-Previous-types:hover {
  background-image: url('../assets/image/monitor/pageHover.png') !important;
  border: 0.5px solid #69008C !important;
  color: #69008C !important;
}

.page-Previous-types-dis {
  padding: 3px 7px 3px 15px !important;
  background: #FFFFFF !important;
  border-radius: 3px !important;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center !important;
  background-size: 4px 8px !important;
  background-repeat: no-repeat !important;
  background-position: 7px center !important;
  background-image: url('../assets/image/monitor/pageHover.png') !important;
  border: 0.5px solid #69008C !important;
  color: #69008C !important;
}

.page-Previous-types-next-dis {
  padding: 3px 15px 3px 7px;
  background: #FFFFFF;
  border-radius: 3px;
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background-size: 4px 8px;
  background-repeat: no-repeat;
  background-position: 48px 8px;
  background-image: url('../assets/image/monitor/pageHovernext.png');
  border: 0.5px solid #69008C;
  color: #69008C;
}

.page-Previous-types-next-en {
  padding: 3px 15px 3px 7px;
  background-position: 40px 8px;
}

.main-pages-cons-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 52px;
}

.main-pages-totals-items {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  color: #333333;
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 250, 250, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}

input::-webkit-credentials-auto-fill-button {
  display: none !important;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input:focus::-webkit-contacts-auto-fill-button {
  opacity: 0;
}

.id-copy-in-device-con {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.id-copy-in-device {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer
}

.ant-modal-content {
  position: relative;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px !important;
  pointer-events: auto !important;
  min-width: 300px !important;
}

.ant-modal-centered .ant-modal {
  min-width: 300px !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid #E1000F !important;
  border-color: #e1000f !important;
  box-shadow: none !important;
  color: #000 !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border: 2px solid #E1000F !important;
  border-color: #e1000f !important;
  box-shadow: none !important;
  color: #000 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, .25) !important;
}